<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  mounted() {
    this.removeSession()
    this.$router.push({name: 'UsersSignIn'})
  }
}
</script>
